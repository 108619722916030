import { useEffect } from 'react'
import AppWrap from '../src/App'
import Router from 'next/router'

import { NextIntlProvider } from 'next-intl'

Router.events.on('routeChangeComplete', (url) => {
    window.ga('set', 'page', url)
    window.ga('send', 'pageview')
})

const MyApp = ({ Component, pageProps: { messages, ...pageProps } }) => {
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles)
        }
    }, [])

    return (
        // <I18nProvider lang={locale} namespaces={messages}>
        <AppWrap>
            <NextIntlProvider messages={messages || {}}>
                <Component {...pageProps} />
            </NextIntlProvider>
        </AppWrap>
    )
}

export default MyApp
