import { ErrorBoundary } from '@sentry/react'
import './sentryInit'

export default ErrorBoundary

// import React, { Component } from 'react'
// import { withScope, captureException, showReportDialog } from '@sentry/browser'
// import './sentryInit'

// class SentryErrorBoundary extends Component {
//     constructor(props) {
//         super(props)
//         this.state = { error: null }
//     }

//     componentDidCatch(error, errorInfo) {
//         this.setState({ error })
//         withScope(scope => {
//             Object.keys(errorInfo).forEach(key => {
//                 scope.setExtra(key, errorInfo[key])
//             })
//             captureException(error)
//         })
//     }

//     render() {
//         if (this.state.error) {
//             // render fallback UI
//             return (
//                 // eslint-disable-next-line
//                 <a onClick={() => showReportDialog()} style={{ color: '#ffffff' }}>
//                     Report Error
//                 </a>
//             )
//         } else {
//             // when there's not an error, render children untouched
//             return this.props.children
//         }
//     }
// }

// export default SentryErrorBoundary
