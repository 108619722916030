import { cloneElement } from 'react'

import { Gate2HomeProvider } from './Context/Gate2HomeContext'

import theme from './Components/theme'
import { ThemeProvider } from '@material-ui/styles'

import SentryErrorBoundary from './Config/SentryErrorBoundary'

// if (typeof navigator !== 'undefined') {
//     console.log(
//         `%c

//    ▄▄▄▄▄        ▄          ▄███▄  ▄▄   ▄▄                       ▄█████▄
//   ██▀  ▀   ▄▄▄  █▄▄▄  ▄▄▄  ▀▀ ▀█▌ █▌   ██  ▄▄▄  ▄▄▄▄▄▄▄▄   ▄▄▄  █▄█▄█▄█▌
//  ██▌  ▄▄▄ ▀  ██ ██▀▀ █▀ ▀█   ▄██  ███████ █▀ ▀█ ██  ██ ▀█ █▀ ▀█  ▀███▀
//  ▐██   ██ █▀▀██ ██   ██▀▀▀ ▄█▀    █▌   ██ █▄ ▄█ ██  ██  █ █▀▀▀▀ ▀▀
//   ▀▀▀▀▀▀   ▀▀▀▀  ▀▀▀  ▀▀▀▀ ██████ ▀▀   ▀▀  ▀▀▀  ▀▀  ▀▀  ▀  ▀▀▀▀    .com
//                                                 Online Virtual Keyboard `,
//         'background: #3d86bc; color: white'
//     )
// }

const AppWrap = ({ children, ...props }) => {
    return (
        <>
            <style jsx global>{`
                body {
                    background-color: #2863a1;
                    background-image: linear-gradient(
                        to bottom,
                        #193c67 47px,
                        #295e9b 148px,
                        #2989d8 432px,
                        #3d86bc 634px
                    );
                    // linear-gradient(to bottom, #193c67 47px, #295e9b 148px, #2989d8 432px, #3d86bc 634px)
                    margin: 0;
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 14px;
                }

                input,
                button,
                select,
                div.vkinput,
                .k_b,
                .TransliterateBox {
                    white-space: pre-wrap; /* css-3 */
                    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
                    white-space: -pre-wrap; /* Opera 4-6 */
                    white-space: -o-pre-wrap; /* Opera 7 */
                    word-wrap: break-word; /* Internet Explorer 5.5+ */

                    font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen-Sans, Ubuntu, Cantarell,
                        'Helvetica Neue', sans-serif, 'Tahoma', 'Times New Roman', 'Arial';
                }

                @media print {
                    * {
                        visibility: hidden;
                        width: 0;
                        height: 0;
                    }
                    #virtualKeyboard {
                        display: none;
                    }
                    .textbox1,
                    .translate-textarea {
                        visibility: visible !important;
                        z-index: 1000;
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100% !important;
                        padding: 2cm;
                        margin: 1cm auto;
                        background: white;
                        float: left;
                    }
                }

                .freetextarea {
                    z-index: 0;
                }
                .freetextarea[contentEditable='false']:empty:not(:focus):before {
                    content: attr(placeholder);
                    color: grey;
                    font-style: italic;
                    text-align: left;
                    direction: ltr;
                }

                .query_text:focus {
                    outline: none;
                }

                 {
                    /* Mobile Extra:  */
                }
                @media (max-width: 720px) {
                    .ghostInput {
                        position: absolute;
                        height: 48px;
                        width: 48px;
                        visibility: hidden;
                    }
                    .freetextarea {
                        border: 0px;
                        padding: 0px;
                        margin: 0px;
                        border-radius: 0px;
                    }
                     {
                        /* .site_name {
                        font-size: 47px;
                    } */
                    }
                    .logo-bubble {
                        width: 33px;
                        height: 32px;
                        background-size: 33px 32px;
                    }
                    .layout {
                        float: none;
                    }
                    .footer-links,
                    .footer-sections {
                        display: none;
                    }
                    .Footer {
                        padding-bottom: 209px;
                    }
                    .Footer .debugger_ru {
                        display: none;
                    }
                }
            `}</style>
            <SentryErrorBoundary>
                <ThemeProvider theme={theme}>
                    <Gate2HomeProvider>{cloneElement(children, props)}</Gate2HomeProvider>
                </ThemeProvider>
            </SentryErrorBoundary>
        </>
    )
}

export default AppWrap
