import { init } from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
// import { createBrowserHistory } from 'history'

// const history = createBrowserHistory()

init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    sampleRate: 1,
    ignoreUrls: [/s7\.addthis\.com/],
    environment: process.env.NODE_ENV
    // integrations: [
    //     new Integrations.BrowserTracing({
    //         // Can also use reactRouterV4Instrumentation
    //         routingInstrumentation: reactRouterV5Instrumentation(history)
    //     })
    // ],
    // tracesSampleRate: 0.05
})

// export { history }

// import { init } from '@sentry/browser'

// init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     ignoreErrors: [
//         // Random plugins/extensions
//         'top.GLOBALS',
//         // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
//         'originalCreateNotification',
//         'canvas.contentDocument',
//         'MyApp_RemoveAllHighlights',
//         'http://tt.epicplay.com',
//         "Can't find variable: ZiteReader",
//         'jigsaw is not defined',
//         'ComboSearch is not defined',
//         'http://loading.retry.widdit.com/',
//         'atomicFindClose',
//         // Facebook borked
//         'fb_xd_fragment',
//         // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
//         // reduce this. (thanks @acdha)
//         // See http://stackoverflow.com/questions/4113268
//         'bmi_SafeAddOnload',
//         'EBCallBackMessageReceived',
//         // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
//         'conduitPage',
//         /chrome-extension/,
//         /Extension context invalidated./i
//     ],
//     blacklistUrls: [
//         // Facebook flakiness
//         /graph\.facebook\.com/i,
//         // Facebook blocked
//         /connect\.facebook\.net\/en_US\/all\.js/i,
//         // Woopra flakiness
//         /eatdifferent\.com\.woopra-ns\.com/i,
//         /static\.woopra\.com\/js\/woopra\.js/i,
//         // Chrome extensions
//         /extensions\//i,
//         /^chrome:\/\//i,
//         /^chrome-extension:\/\//i,
//         // Other plugins
//         /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
//         /webappstoolbarba\.texthelp\.com\//i,
//         /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
//     ],
//     beforeSend(event, hint) {
//         const error = hint.originalException
//         if (error && error.message && error.message.match(/Extension context invalidated./i)) {
//             return null
//         }
//         return event
//     }
// })
